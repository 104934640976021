import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"
import ImageCard from "../../components/image-card"
import Breadcrumbs from "../../components/breadcrumbs"
import {
  CovidSafetyLink,
  HomeLink,
  TechnologyLink,
} from "../../components/links"
import Alert from "../../components/alert"

const TechnologyPage = ({ data }) => {
  const images = {
    lab: getImage(data.lab),
    panx: getImage(data.panx),
    wallx: getImage(data.wallx),
    zoom: getImage(data.zoom),
  }

  return (
    <>
      <SEO title="Technology" />

      <Breadcrumbs>
        <HomeLink />
        <TechnologyLink />
      </Breadcrumbs>

      <article className="prose dark:prose-invert max-w-none prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary">
        <h2>Technology</h2>
        <Alert>
          <span className="text-brand-primary dark:text-brand-secondary font-bold">
            <i>
              We use modern technology to provide optimal care for our patients.
            </i>
          </span>
        </Alert>
        <div className="grid grid-cols-1 gap-x-4 md:grid-cols-3">
          <div>
            <h3>COVID-19 Safety</h3>
            <p>
              We take health and safety of our patients very seriously.
              <br />
              <CovidSafetyLink title="Learn more" />
            </p>
          </div>
          <div>
            <h3>X-ray Imaging System</h3>
            <h4>Digital X-rays</h4>
            <p>Allows us to quickly zoom into your teeth for assessement.</p>
            <h4>Panoramic X-ray</h4>
            <p>
              Allows us to see a panoramic image of your teeth, roots,
              surrounding bone, and jaw joints.
            </p>
            <h4>Lateral cephalogram</h4>
            <p>
              Allows us to view the side of your skull, inclination of front
              teeth, length of the jaw, and your facial profile.
            </p>
          </div>
          <div>
            <h3>Digital Intraoral Scanner</h3>
            <p>
              Our digital scanner takes a fast, accurate 3-D record of your
              mouth without gooey impressions at first. This is helpful for
              patients with a strong gag reflex.
            </p>
            <p>
              Allows us to send your impression quickly for planning clear
              aligners.
            </p>
          </div>
          <div>
            <h3>On-site Specialist Laboratory</h3>
            <p>
              Allows us to quickly make custom orthodontic appliances for our
              patients. These include orthodontic retainers, night-guards,
              removable appliances, and tooth whitening trays.
            </p>
          </div>
          <div>
            <h3>Zoom! Whitening</h3>
            <p>In-chair professional tooth whitening.</p>
            <p>We also offer take-home kits.</p>
          </div>
        </div>

        <div className="divider"></div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <ImageCard
            image={
              <GatsbyImage
                image={images.wallx}
                alt="Dentsply Sirona Digital X-ray"
                className="rounded-2xl"
              />
            }
            name="Dentsply Sirona Digital X-ray"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.panx}
                alt="Dentsply Sirona Panoramic X-ray"
                className="rounded-2xl brightness-95"
              />
            }
            name="Dentsply Sirona Panoramic X-ray"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.zoom}
                alt="Philips Zoom! Whitening"
                className="rounded-2xl brightness-95"
              />
            }
            name="Philips Zoom! Whitening"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.lab}
                alt="On-site Specialist Laboratory"
                className="rounded-2xl"
              />
            }
            name="On-site Specialist Laboratory"
          ></ImageCard>
        </div>
      </article>
    </>
  )
}
export default TechnologyPage

export const query = graphql`
  query {
    lab: file(relativePath: { eq: "technology/lab.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          transformOptions: { grayscale: true }
        )
      }
    }
    panx: file(relativePath: { eq: "technology/panx.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    wallx: file(relativePath: { eq: "technology/wallx.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    zoom: file(relativePath: { eq: "technology/zoom.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
